import React from 'react'
import { Link } from "gatsby";
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'


export const ProductsPageTemplate = ({ image1, title1, subheading1, image2, title2, subheading2, }) => (
  <div>
    <Link
    to="/products/audioswitch/"
    
    
        >
    <div
      className="full-width-image margin-top-0 products-item"
      style={{
        backgroundImage: `url(${
          !!image1.childImageSharp ? image1.childImageSharp.fluid.src : image1
        })`
        }}
    >
      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen has-text-centered"
          style={{
            boxShadow:
              'rgb(245, 126, 77) 0.5rem 0px 0px, rgb(245, 126, 77) -0.5rem 0px 0px',
            backgroundColor: 'rgb(245, 126, 77)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {title1}
        </h1>
        <h3
          className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen has-text-centered"
          style={{
            boxShadow:
              'rgb(245, 126, 77) 0.5rem 0px 0px, rgb(245, 126, 77) -0.5rem 0px 0px',
            backgroundColor: 'rgb(245, 126, 77)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {subheading1}
        </h3>

      </div>
    </div>
    </Link>         
   
<Link
                  to="/products/truestand/"
                  
                  
                >
    <div
      className="full-width-image margin-top-0 products-item"
      style={{
        backgroundImage: `url(${
          !!image2.childImageSharp ? image2.childImageSharp.fluid.src : image2
        })`
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen has-text-centered"
          style={{
            boxShadow:
              'rgb(245, 126, 77) 0.5rem 0px 0px, rgb(245, 126, 77) -0.5rem 0px 0px',
            backgroundColor: 'rgb(245, 126, 77)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {title2}
        </h1>
        <h3
          className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen has-text-centered"
          style={{
            boxShadow:
              'rgb(245, 126, 77) 0.5rem 0px 0px, rgb(245, 126, 77) -0.5rem 0px 0px',
            backgroundColor: 'rgb(245, 126, 77)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {subheading2}
        </h3>

      </div>
    </div>
  </Link>         
  </div>
)

ProductsPageTemplate.propTypes = {
  image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title1: PropTypes.string,
  subheading1: PropTypes.string,
  image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title2: PropTypes.string,
  subheading2: PropTypes.string,
}

const ProductsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ProductsPageTemplate
        image1={frontmatter.image1}
        title1={frontmatter.title1}
        subheading1={frontmatter.subheading1}
        image2={frontmatter.image2}
        title2={frontmatter.title2}
        subheading2={frontmatter.subheading2}
      />
    </Layout>
  )
}

ProductsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ProductsPage

export const pageQuery = graphql`
  query ProductsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "products-page" } }) {
      frontmatter {
        title1
        image1 {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        subheading1
      
      }
      frontmatter {
        title2
        image2 {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        subheading2
      
      }
    }
  }
`
